import React from 'react';
import { FEButton, FESpinner } from 'components';
import { DesktopCartButtonProps } from './props';
import {IMG_IC_24_BAG_ONCOLOR, IMG_IC_24_BAGNEW} from 'images';
import style from './style.module.css';
import {useClickOutside, useMediaQuery} from 'ui-kit/hooks';
import { useLinks } from 'hooks/router';
import { useNavigate } from 'react-router-dom';
import { Product } from './components';
import { useUnit } from 'effector-react';
import {
  changeConstructorQuantity,
  changeDishQuantity,
  deleteConstructor,
  deleteDish,
} from 'domains/cart';
import { CurrencySymbol } from 'models';
import { useTranslation } from 'react-i18next';

const DesktopCartButton = ({
  disabled,
  showLoader,
  dishes,
  constructors,
  price,
  currency,
}: DesktopCartButtonProps & { currency: CurrencySymbol }) => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState<boolean>(false);
  const ref = React.useRef<HTMLDivElement>(null);
  const isDesktop = useMediaQuery(`(min-width: ${500}px)`);


  const navigate = useNavigate();
  const { cartLink } = useLinks();

  useClickOutside(ref, () => setOpen(false));

  React.useEffect(() => {
    if (!dishes.length && !constructors.length) {
      setOpen(false);
    }
  }, [dishes, constructors]);

  const [
    onChangeDishQuantity,
    onDeleteDish,
    onChangeConstructorQuantity,
    onDeleteConstructor,
  ] = useUnit([
    changeDishQuantity,
    deleteDish,
    changeConstructorQuantity,
    deleteConstructor,
  ]);

  return (
    <div ref={ref} className={style.root}>
      <button className={style.cartButton} onClick={() => setOpen(!open)}>
        <div className={style.profileCartContainer}>
          <img className='button-icon-24' src={IMG_IC_24_BAGNEW} alt='' />
          {isDesktop && <p className={style.cartLabel}>{t('catalog.cart.label')}</p>}
          {/*<p className='headline'>*/}
          {/*  {price ? `${price.total} ${currency}` : t('catalog.cart.label')}*/}
          {/*</p>*/}
        </div>
      </button>
      {open && (
        <div className={style.cart}>
          <div className={style.cartContainer}>
            <div className={style.cartContent}>
              {dishes.map((dish, index) => (
                <Product
                  key={dish.orderItemId}
                  currency={currency}
                  product={dish}
                  type='DISH'
                  onChangeQuantity={(val) =>
                    onChangeDishQuantity({ index, quantity: val })
                  }
                  onDelete={() => onDeleteDish({ index })}
                  isValidating={showLoader}
                />
              ))}
              {constructors.map((constructor, index) => (
                <Product
                  key={constructor.orderItemId}
                  currency={currency}
                  product={constructor}
                  type='CONSTRUCTOR'
                  onChangeQuantity={(val) =>
                    onChangeConstructorQuantity({ index, quantity: val })
                  }
                  onDelete={() => onDeleteConstructor({ index })}
                  isValidating={showLoader}
                />
              ))}
            </div>
            <div className={style.submit}>
              <div className={style.submitContainer}>
                <div className={style.submitContent}>
                  <div className={style.price}>
                    <p>{t('catalog.cart.priceLabel')}</p>
                    <p>{`${price?.total} ${currency}`}</p>
                  </div>
                  <FEButton
                    {...{ disabled }}
                    onClick={() => {
                      navigate(cartLink);
                      setOpen(false);
                    }}
                  >
                    {t('catalog.cart.actionButtonLabel')}
                  </FEButton>
                </div>
              </div>
            </div>
            {showLoader && (
              <div className={style.spinnerContainer}>
                <FESpinner size='medium' />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default DesktopCartButton;
