import React from 'react';
import { useMediaQuery } from 'ui-kit/hooks';
import FEHeaderProps from './props';
import { IMG_PLACEHOLDER_LOGO } from 'images';
import style from './style.module.css';
import { FEBar } from 'components';
import { Confirmation, Menu } from './components';
import { MOBILE_MENU_WIDTH } from 'const';
import { useTranslation } from 'react-i18next';
import { useUnit } from 'effector-react';
import { $currency } from 'domains/cartography';
import { $constructors, $dishes, $isValidating, $price } from 'domains/cart';
import { DesktopCartButton } from 'pages/catalogDesktop/components';

const FEHeader = ({
  orderType,
  address,
  restaurant,
  onAddressBarClick,
  onLogoClick,
  onCloseConfirmation,
  onDeclineConfirmation,
  isOpenConfirmation,
}: FEHeaderProps) => {
  const { t } = useTranslation();
  const isDesktop = useMediaQuery(`(min-width: ${500}px)`);
  const logoRef = React.useRef<HTMLImageElement>(null);
  const [logoWidth, setLogoWidth] = React.useState<number>(0);
  const currency = useUnit($currency);

  const [dishes, constructors, isValidating, price] = useUnit([
    $dishes,
    $constructors,
    $isValidating,
    $price,
  ]);

  React.useLayoutEffect(() => {
    if (logoRef.current) {
      setLogoWidth(logoRef.current.getBoundingClientRect().width);
    }
  }, [isDesktop]);

  return (
    <>
        {isDesktop ? (
          <>
            <div className={style.header}>
              <div className={style.container}>
                <div className={style.appData}>
                  <img
                    ref={logoRef}
                    className={style.logo}
                    src={IMG_PLACEHOLDER_LOGO}
                    alt=''
                    onClick={onLogoClick}
                  />
                  <div
                    className={style.addressContainer}
                    style={{
                      width: `calc(100% - ${logoWidth}px)`,
                    }}
                  >
                    <FEBar
                      onClick={onAddressBarClick}
                      label={
                        orderType === 'COURIER'
                          ? t('cartography.header.deliveryLabel')
                          : t('cartography.header.pickupLabel')
                      }
                      value={orderType === 'COURIER' ? address : restaurant}
                      placeholder={t('cartography.header.placeholder')}
                    />
                    <Confirmation
                      value={orderType === 'COURIER' ? address : restaurant}
                      open={isOpenConfirmation}
                      onClose={onCloseConfirmation}
                      onDeclineConfirmation={onDeclineConfirmation}
                    />
                  </div>
                </div>
                <div className={style.headerButtonsContainer}>
                  <div className={style.userData}>
                    <Menu />
                  </div>
                  {isDesktop && (
                    <DesktopCartButton
                      currency={currency}
                      price={price}
                      showLoader={isValidating}
                      disabled={
                        constructors.length === 0 && dishes.length === 0
                      }
                      dishes={dishes}
                      constructors={constructors}
                    />
                  )}
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className={style.header}>
              <div className={style.container}>
                <div className={style.menuSide}>
                  <img
                    className={style.logo}
                    src={IMG_PLACEHOLDER_LOGO}
                    alt=''
                    onClick={onLogoClick}
                  />
                  <Menu />
                </div>
              </div>
            </div>
          </>
        )}
      {!isDesktop && (
        <div className={style.dataSide}>
          <div className={style.addressContainer}>
            <FEBar
              onClick={onAddressBarClick}
              label={
                orderType === 'COURIER'
                  ? t('cartography.header.deliveryLabel')
                  : t('cartography.header.pickupLabel')
              }
              value={orderType === 'COURIER' ? address : restaurant}
              placeholder={t('cartography.header.placeholder')}
            />
            <Confirmation
              value={orderType === 'COURIER' ? address : restaurant}
              open={isOpenConfirmation}
              onClose={onCloseConfirmation}
              onDeclineConfirmation={onDeclineConfirmation}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default FEHeader;
export type { FEHeaderProps };
