import { MOBILE_WIDTH } from 'const';
import {
  $promotionProduct,
  $timeTo,
  $urgent,
  repeatOrderFx,
  $promoCode,
  $isOrderRepeating,
} from 'domains/cart';
import {
  $type,
  $address,
  $restaurant,
  $deliveryZone,
  $currency,
} from 'domains/cartography';
import { $catalogId } from 'domains/catalog';
import {
  $isPendingOrders,
  $orders,
  $pendingGetOrders,
  $user,
  getOrdersList,
  Order,
  ordersPageGate,
} from 'domains/profile';
import { useGate, useUnit } from 'effector-react';
import { useLinks } from 'hooks/router';
import { AuthClient } from 'api/base';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { FEBottomSheet, FEDialog, FESpinner } from 'components';
import { useMediaQuery } from 'ui-kit/hooks';
import { Content } from './components';
import style from './style.module.css';
import { useTranslation } from 'react-i18next';

const OrdersPage = ({ open }: { open: boolean }) => {
  const { t } = useTranslation();
  const isDesktop = useMediaQuery(`(min-width: ${MOBILE_WIDTH}px)`);
  const navigate = useNavigate();

  const goBack = () => {
    const isDefault =
      ((window.history.state && window.history.state.key) || 'default') ===
      'default';

    if (isDefault) return navigate('/', { replace: true });
    return navigate(-1);
  };

  useGate(ordersPageGate);

  const [
    orders,
    isPendingOrders,
    catalogId,
    promoCode,
    promotionProduct,
    type,
    address,
    restaurant,
    user,
    timeTo,
    urgent,
    deliveryZone,
    isOrderRepeating,
    onRepeatOrder,
    currency,
    pendingGetOrders,
    handleGetOrders,
  ] = useUnit([
    $orders,
    $isPendingOrders,
    $catalogId,
    $promoCode,
    $promotionProduct,
    $type,
    $address,
    $restaurant,
    $user,
    $timeTo,
    $urgent,
    $deliveryZone,
    $isOrderRepeating,
    repeatOrderFx,
    $currency,
    $pendingGetOrders,
    getOrdersList,
  ]);

  const { authLink, cartLink } = useLinks();

  React.useEffect(() => {
    if (AuthClient.isAuth === false) {
      navigate(authLink, { replace: true });
    }
  }, [authLink, navigate]);

  const handleRepeatOrder = (order: Order) =>
    catalogId &&
    onRepeatOrder({
      order,
      catalogId,
      promoCode,
      promotionProduct,
      type,
      address,
      restaurant,
      user,
      timeTo,
      urgent,
      deliveryZone,
    }).then(() => navigate(cartLink, { replace: true }));

  const handleScroll = useCallback(
    (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
      const { scrollHeight, scrollTop } = e.currentTarget;
      const orderHeight = 327;

      if (scrollHeight - scrollTop < orderHeight * 3) {
        handleGetOrders({ limit: 15, offset: orders.length });
      }
    },
    [handleGetOrders, orders],
  );

  return isDesktop ? (
    <>
      <FEDialog
        open={open}
        showCloseButton={!isOrderRepeating}
        closeOnOverlayClick={!isOrderRepeating}
        onClose={goBack}
        render={({ onClose }) => (
          <>
            <div onScroll={handleScroll} className={style.root}>
              <div className={style.dialogHeader}>
                <h3 className='title'>{t('orders.title')}</h3>
                {/* <button
                  className={clsx('button-icon-24', style.iconButton)}
                  onClick={() => {}}
                /> */}
              </div>
              <Content
                {...{
                  currency,
                  pendingList: pendingGetOrders,
                  onClose,
                  orders,
                  isPendingOrders,
                  onRepeatOrder: ({ order }) => handleRepeatOrder(order),
                }}
              />
            </div>
            {isOrderRepeating && (
              <div className={style.spinnerContainer}>
                <FESpinner />
              </div>
            )}
          </>
        )}
      />
    </>
  ) : (
    <>
      <FEBottomSheet
        open={open}
        closeOnOverlayClick={!isOrderRepeating}
        onClose={goBack}
        render={({ onClose }) => (
          <>
            <div onScroll={handleScroll} className={style.root}>
              <div className={style.bottomSheetHeader}>
                <div className={style.side}>
                  <h3 className='title'>{t('orders.title')}</h3>
                  {/* <button
                    className={clsx('button-icon-24', style.iconButton)}
                    onClick={() => {}}
                  /> */}
                </div>
                <button
                  tabIndex={0}
                  className='closeButton-mobile'
                  onClick={(e) => {
                    onClose();
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                />
              </div>
              <Content
                {...{
                  currency,
                  pendingList: pendingGetOrders,
                  onClose,
                  orders,
                  isPendingOrders,
                  onRepeatOrder: ({ order }) => handleRepeatOrder(order),
                }}
              />
            </div>
            {isOrderRepeating && (
              <div className={style.spinnerContainer}>
                <FESpinner />
              </div>
            )}
          </>
        )}
      />
    </>
  );
};

export default OrdersPage;
