import BaseAPIRepository, { BaseAPIResponse } from 'api/base';
import { AxiosResponse } from 'axios';
import { BASE_URL } from 'const';
import { FERequestErrorsHandler } from 'utils';
import {
  BonusTransactionRequestV1,
  CardTransactionRequestV1,
  CashTransactionRequestV1,
  SberpayTransactionRequestV1,
  TerminalTransactionRequestV1,
  GetFailTransactionRequestV1,
  GetSuccessTransactionRequestV1,
  SBPTransactionRequestV1,
} from '.';
import {
  SBPTransactionResponseV1,
  BonusTransactionResponseV1,
  CardTransactionResponseV1,
  CashTransactionResponseV1,
  SberpayTransactionResponseV1,
  TerminalTransactionResponseV1,
} from '.';
import SavedTransactionRequestV1 from './models/request/SavedTransactionRequestV1';
import SavedTransactionResponseV1 from './models/response/SavedTransactionResponseV1';

const transactionsRepositoryV1 = {
  card: (request: CardTransactionRequestV1, signal?: AbortSignal) =>
    new Promise<CardTransactionResponseV1>(async (resolve, reject) => {
      try {
        let url = `${BASE_URL}/not-secure/api/v1/transactions/card`;
        let result: AxiosResponse<BaseAPIResponse<CardTransactionResponseV1>> =
          await BaseAPIRepository.post<
            BaseAPIResponse<CardTransactionResponseV1>
          >(url, request, { signal });
        resolve(result.data.result);
      } catch (error: any) {
        FERequestErrorsHandler(error, reject);
      }
    }),
  saved: (request: SavedTransactionRequestV1, signal?: AbortSignal) =>
    new Promise<SavedTransactionResponseV1>(async (resolve, reject) => {
      try {
        let url = `${BASE_URL}/not-secure/api/v1/transactions/card`;
        let result: AxiosResponse<BaseAPIResponse<SavedTransactionResponseV1>> =
          await BaseAPIRepository.post<
            BaseAPIResponse<SavedTransactionResponseV1>
          >(url, request, { signal });
        resolve(result.data.result);
      } catch (error: any) {
        FERequestErrorsHandler(error, reject);
      }
    }),

  terminal: (request: TerminalTransactionRequestV1, signal?: AbortSignal) =>
    new Promise<TerminalTransactionResponseV1>(async (resolve, reject) => {
      try {
        let url = `${BASE_URL}/not-secure/api/v1/transactions/terminal`;
        let result: AxiosResponse<
          BaseAPIResponse<TerminalTransactionResponseV1>
        > = await BaseAPIRepository.post<
          BaseAPIResponse<TerminalTransactionResponseV1>
        >(url, request, { signal });
        resolve(result.data.result);
      } catch (error: any) {
        FERequestErrorsHandler(error, reject);
      }
    }),

  cash: (request: CashTransactionRequestV1, signal?: AbortSignal) =>
    new Promise<CashTransactionResponseV1>(async (resolve, reject) => {
      try {
        let url = `${BASE_URL}/not-secure/api/v1/transactions/cash`;
        let result: AxiosResponse<BaseAPIResponse<CashTransactionResponseV1>> =
          await BaseAPIRepository.post<
            BaseAPIResponse<CashTransactionResponseV1>
          >(url, request, { signal });
        resolve(result.data.result);
      } catch (error: any) {
        FERequestErrorsHandler(error, reject);
      }
    }),

  sberpay: (request: SberpayTransactionRequestV1, signal?: AbortSignal) =>
    new Promise<SberpayTransactionResponseV1>(async (resolve, reject) => {
      try {
        let url = `${BASE_URL}/not-secure/api/v1/transactions/sberpay`;
        let result: AxiosResponse<
          BaseAPIResponse<SberpayTransactionResponseV1>
        > = await BaseAPIRepository.post<
          BaseAPIResponse<SberpayTransactionResponseV1>
        >(url, request, { signal });
        resolve(result.data.result);
      } catch (error: any) {
        FERequestErrorsHandler(error, reject);
      }
    }),

  sbp: (request: SBPTransactionRequestV1, signal?: AbortSignal) =>
    new Promise<SBPTransactionResponseV1>(async (resolve, reject) => {
      try {
        let url = `${BASE_URL}/not-secure/api/v1/sbp/link`;
        let result: AxiosResponse<BaseAPIResponse<SBPTransactionResponseV1>> =
          await BaseAPIRepository.post<
            BaseAPIResponse<SBPTransactionResponseV1>
          >(url, request, { signal });
        resolve(result.data.result);
      } catch (error: any) {
        FERequestErrorsHandler(error, reject);
      }
    }),

  bonus: (request: BonusTransactionRequestV1, signal?: AbortSignal) =>
    new Promise<BonusTransactionResponseV1>(async (resolve, reject) => {
      try {
        let url = `${BASE_URL}/not-secure/api/v1/transactions/bonus`;
        let result: AxiosResponse<BaseAPIResponse<BonusTransactionResponseV1>> =
          await BaseAPIRepository.post<
            BaseAPIResponse<BonusTransactionResponseV1>
          >(url, request, { signal });
        resolve(result.data.result);
      } catch (error: any) {
        FERequestErrorsHandler(error, reject);
      }
    }),

  getSuccessTransaction: (
    { orderNumber }: GetSuccessTransactionRequestV1,
    signal?: AbortSignal,
  ) =>
    new Promise<string>(async (resolve, reject) => {
      try {
        let url = `${BASE_URL}/not-secure/api/v1/transactions/card/success/${orderNumber}`;
        let result: AxiosResponse<BaseAPIResponse<string>> =
          await BaseAPIRepository.get<BaseAPIResponse<string>>(url, { signal });
        resolve(result.data.result);
      } catch (error: any) {
        FERequestErrorsHandler(error, reject);
      }
    }),

  getFailTransaction: (
    { orderNumber }: GetFailTransactionRequestV1,
    signal?: AbortSignal,
  ) =>
    new Promise<string>(async (resolve, reject) => {
      try {
        let url = `${BASE_URL}/not-secure/api/v1/transactions/card/fail/${orderNumber}`;
        let result: AxiosResponse<BaseAPIResponse<string>> =
          await BaseAPIRepository.get<BaseAPIResponse<string>>(url, { signal });
        resolve(result.data.result);
      } catch (error: any) {
        FERequestErrorsHandler(error, reject);
      }
    }),
};

export default transactionsRepositoryV1;
